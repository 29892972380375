








































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import City from "@/models/City";
import { FieldEmail, FieldPhone } from "@/components/account";
import rules from "@/components/account/rules";
import { mapActions, mapState } from "vuex";
import { notifyInfo } from "@/components/Notification";

export default Vue.extend({
  name: "btn-edit-company",
  components: {
    FieldEmail,
    FieldPhone,
  },
  props: {
    company: Object,
  },
  data() {
    return {
      dialog: false,
      rules: rules,
      formValid: false,
      toEdit: "",
      stateCities: [],
      loadingCities: false,
      state: 0,
      form: {
        name: "",
        email: "",
        phone: "",
        description: "",
        address: "",
        mailingZip: "",
        cityId: 0,
      },
    };
  },
  watch: {
    state: function (stateId: number): void {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities: City[]) => {
        this.loadingCities = false;
        (this.stateCities as any) = cities;
      });
    },
  },
  computed: {
    ...mapState("crmCompaniesModule", ["loading"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    ...mapActions("crmCompaniesModule", [
      "actCreateCompany",
      "actUpdateCompany",
      "actGetCompanies",
    ]),
    edit() {
      this.actUpdateCompany({ uuid: this.toEdit, body: this.form }).then(() => {
        this.dialog = false;
        this.actGetCompanies();
        notifyInfo("Company update successfully");
      });
    },
    setToEdit() {
      this.toEdit = this.company.uuid;
      this.form.name = this.company.name;
      this.form.email = this.company.email;
      this.form.phone = this.company.phone.replace("+1", "");
      this.form.description = this.company.description;
      this.form.mailingZip = this.company.mailingZip;
      this.form.address = this.company.address;
      this.form.cityId = this.company.mailingCity
        ? this.company.mailingCity.id
        : 0;
      this.state = this.company.mailingCity
        ? this.company.mailingCity.state.id
        : 0;
    },
  },
  async mounted() {
    await this.actListStates();
    this.setToEdit();
  },
});
